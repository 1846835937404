// window.onload = function(){
//     let link = document.createElement("link");
//     link.href = "https://fonts.googleapis.com/css?family=Germania+One";
//     link.rel = "stylesheet";
//     link.type = "text/css";
//     document.head.appendChild(link);
// };
// Send messages to ServiceWorker
let messageChannel = new MessageChannel();
// Receive reply from ServiceWorker
messageChannel.port1.onmessage = receiveMessage;

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register('/sw.min.js').then(function (reg) {
		// Receive messages from ServiceWorker
		navigator.serviceWorker.addEventListener("message", receiveMessage);
		reg.onupdatefound = onUpdateFound;
		reg.pushManager.getSubscription().then(function(sub) {
			if (sub === null) {
				// Update UI to ask user to register for Push
				showSubscribeButton();
			} else {
				// We have a subscription, update the database
				updateSubscription(sub);
			}
		});
	}).catch(function (e) {
		console.error('Error during service worker registration:', e);
	});
	let deferredPrompt;
	window.addEventListener('beforeinstallprompt', function (e) {
		console.log('beforeinstallprompt Event fired');
		deferredPrompt = e;
		e.preventDefault();
		let button = document.getElementById("saveToDesktop");
		button.classList.remove("hide");
		button.addEventListener("click", function (ev) {
			ev.preventDefault();
			if (deferredPrompt !== undefined) {
				deferredPrompt.prompt();
				deferredPrompt.userChoice.then(function (choiceResult) {

					console.log('User ' + choiceResult.outcome +' home screen install');
					deferredPrompt = undefined;
					button.classList.add("hide");
				});
			}
		});
		return false;
	});

}
function onUpdateFound() {
	const installingWorker = this.installing;
	installingWorker.onstatechange = function () {
		switch (installingWorker.state) {
			case 'installed':
				let sentData;
				if (navigator.serviceWorker.controller) {
					sentData = {
						text: 'New or updated content is available.',
						dismiss: 4000
					};
				} else {
					sentData = {
						text: 'Content is now available offline!',
						dismiss: 4000
					};
				}
				console.log(sentData.text);
				//TODO:  notifyUser(sentData);
				break;
			case 'redundant':
				console.warn('The installing service worker became redundant.');
				break;
		}
	};
}

function subscribeUser() {
	if (!'serviceWorker' in navigator) {
	    return;
	}
	const btn = document.getElementById("subUser");
	btn.disabled = true;
    navigator.serviceWorker.ready.then(function(reg) {

        reg.pushManager.subscribe({
            userVisibleOnly: true
        }).then(function(sub) {
	        console.log('Subscription approoved!');
	        btn.innerHTML = "Subscription approoved!";
	        setTimeout(function(){
		        btn.classList.add("hide");
            }, 2000);
	        let options = {
		        body: 'You can now receive my awesome notifications!',
		        icon: '/assets/img/drakoulas.png',
		        vibrate: [100, 50, 100],
		        data: {
			        dateOfArrival: Date.now(),
			        primaryKey: 1
		        },
		        actions: [
			        {
				        action: '/',
				        title: 'Like!',
				        icon: '/assets/img/workinprog@1x.png'
			        }
		        ]
	        };
	        let notification = new SWNotification("Subscription approoved!", options);
	        updateSubscription(sub, notification);

        }).catch(function(e) {
            if (Notification.permission === 'denied') {
	            btn.innerHTML = "Permission for notifications was denied..";
                console.warn('Permission for notifications was denied..');
            } else {
	            btn.innerHTML = "Unable to subscribe to push!";
                console.error('Unable to subscribe to push', e);
            }
	        setTimeout(function(){
		        btn.classList.add("hide");
	        }, 2000);
        });
    });
}


function showSubscribeButton(){
	console.log('Not subscribed to push service!');
	document.getElementById("subUser").classList.remove("hide");
}

function updateSubscription(sub, notification) {
	console.log('Endpoint URL: ', sub.endpoint);
    let options = {
        endpoint: sub.endpoint
    };
    if(navigator.serviceWorker.controller){
	    let message = new WorkerMessage("whatIsMyIP", options, notification);
	    navigator.serviceWorker.controller.postMessage(message, [messageChannel.port2]);
    }
}

function receiveMessage(event){
	if(!event.data){
		return;
	}
	let message = event.data;
	console.log(message);
}

function SWNotification(title, options){
	const TITLE = "Greetings";
	const BODY = "Failed to retrieve message";
	const ICON = "/assets/img/drakoulas.png";
	const TAG = "ServiceWorker";
    this.title = title || TITLE;
    this.options = options || {};
    this.options.body = this.options.body || BODY;
    this.options.icon = this.options.icon || ICON;
    this.options.tag = this.options.tag || TAG;
    return this;
}
SWNotification.prototype.show = function(){
	let parent = this;
	navigator.serviceWorker.getRegistration().then(function(reg) {
		reg.showNotification(parent.title, parent.options);
	});
};
function WorkerMessage(command, options, notification){
    this.command = command;
    this.options = options;
    this.notification = notification;
    return this;
}
